import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  drawPoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 12, 9, -6],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});
    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.layer['image'] =2;
    brd1.options.layer['point'] =3;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    //
    placeTitle(brd1, 'Perimeter of a Rectangle', '(Tap on a side to select it. Continue until all sides are selected)');
    //
    var analytics = placeShuffle(brd1, 'left');
    analytics.setLabel('Tap to Shuffle');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeErase(brd1);
    erase.setLabel('Tap to Erase & Restart')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    var rght = placeImage(brd1, '/assets/checkmark.svg', 3.5, 6., 1.,  0);
  //  placeMiddleText(brd1, 0, 12, ()=>'Given Number =' + NumList[i]);
    /////////////////////////////////////////////////////////
    var Pt1 = drawPoint(brd1, -3, -3);
    Pt1.setAttribute({visible:false});
    //
    var Pt2 = drawPoint(brd1,  3,  -3);
    Pt2.setAttribute({visible:false});
    //
    var Pt3 = drawPoint(brd1,  -3,  3);
    Pt3.setAttribute({visible:false});
    var Pt4 = drawPoint(brd1,  3,  3);
    Pt4.setAttribute({visible:false});
    ////////
    var shape =placeSq(brd1, [()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt4.X(), ()=>Pt4.Y()],'green');
    shape.setAttribute({fillOpacity:0.5});
    console.log('dsd');
    var side1 = placeTapeHori(brd1, Pt1, Pt2,'');
    side1.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
 ////////////////////////////////////////
    var side3 = placeTapeHori(brd1, Pt3, Pt4,'');
    side3.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false},  strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
//////////////////////////////////////////////////////
    var side2 = placeTapeVert(brd1, Pt1, Pt3,'');
    side2.setAttribute({highlight:true, fillOpacity:0.4,  point1:{visible:false},point2:{visible:false}, strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
/////////////////////////////////////////////////////////////////////////////////////
    var side4 = placeTapeVert(brd1, Pt2, Pt4,'');
    side4.setAttribute({highlight:true, fillOpacity:0.4,  point1:{visible:false},point2:{visible:false},  strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
//////////////////////////////////////////////////action///////////////////
var i=0, j=0, k=0, l=0;
rght.setAttribute({visible:()=>i+j+k+l==4});
side1.on('down', function(){i=1; side1.setAttribute({strokeColor:'blue', label:{visible:true}})});
side2.on('down', function(){j=1; side2.setAttribute({strokeColor:'blue', label:{visible:true}})});
side3.on('down', function(){k=1; side3.setAttribute({strokeColor:'blue', label:{visible:true}})});
side4.on('down', function(){l=1; side4.setAttribute({strokeColor:'blue', label:{visible:true}})});
var findSum = function(){return (i*(Pt2.X()-Pt1.X())+ j*(Pt2.Y()-Pt1.Y())+ k*(Pt2.X()-Pt1.X())+l*(Pt2.Y()-Pt1.Y())).toFixed(0)};
var firstText =  function(){if(i==1){return (Pt2.X()-Pt1.X()).toFixed(0)} else{return ''}}
var secondText = function(){if(j==1){return (Pt2.Y()-Pt3.Y()).toFixed(0)}else{return ''}}
var thirdText =  function(){if(k==1){return (Pt4.X()-Pt3.X()).toFixed(0)} else{return ''}}
var fourthText = function(){if(l==1){return (Pt4.Y()-Pt1.Y()).toFixed(0)} else{return ''}}
var plusOne = function(){if(i==1 && j==1 || i==1 && k==1 || i==1 && l==1){return ' + '}else{return ''}}
var plusTwo  = function(){if(j==1 && k==1 || j==1 && l ==1){return ' + '}else{return ''}}
var plusThree = function(){if(k==1 && l==1){return ' + '}else{return ''}}
placeText(brd1, 0, 8.5, 32 , 'Perimeter = ?');
placeText(brd1, 0, 6.5, 32, function(){return  ''+firstText() +plusOne()+ secondText()+ plusTwo()+ thirdText() +plusThree()+ fourthText() + ' = '+ findSum()});

    ////////////////////////////////////////////////////////////////////////////
    function movePt2(){
      let x = 3+Math.round(4*Math.random());
      let y = 3+Math.round(4*Math.random());
      Pt4.moveTo([x, y],100);
      Pt2.moveTo([x, -3],100);
      Pt3.moveTo([-3, y],100);
    }

//    erase.on('down', function(){checkColorFront();checkColorTop(); checkColorSide();})

    function clearVisibility(){
      //rght.setAttribute({visible:false});
      //wrng.setAttribute({visible:false});
      i=0; j=0; k=0; l=0;
      side1.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeColor:'grey', label:{visible:false, color:'blue'}});
      side2.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeColor:'grey', label:{visible:false, color:'blue'}});
      side3.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeColor:'grey', label:{visible:false, color:'blue'}});
      side4.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeColor:'grey', label:{visible:false, color:'blue'}});
      }
    analytics.on('down', function(){
      clearVisibility();
      movePt2();
    });
   erase.on('down', function(){clearVisibility();
     Pt4.moveTo([3,3], 100);
     Pt2.moveTo([3,-3], 100);
     Pt3.moveTo([-3,3], 100);
   });
///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;

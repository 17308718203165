<template>
  <div>
    <h3 ref="intro">
      What is Perimeter of a Geometric Shape?
    </h3>
    <p>
      Perimeter of a geometric shape is the length of the boundary curve.
      Imagine you have to walk along the border of a rectangle or a square until you get back to the same point.
      Then the distance travelled is equal to the perimeter of the rectangle or the square.
    </p>
    <h3 ref="formula">
      Perimeter of a Rectangle
    </h3>
    <p>
      Perimeter \(R\) of a rectangle with length \(L\) and width \(W\) is given as:
      $$ R = 2(L+W)$$
    </p>
    <h3 ref="formula">
      Perimeter of a Square
    </h3>
    <p>
      In a square, the length is the same as the width, i.e., \(l =w\).
      Thus, substituting \(L =W\) in the expression for the perimeter of a rectangle, we can obtain the expression for the perimeter of a square:
      $$ S = 2(L+L) = 4L$$
    </p>
    <h3 ref="pg">
      MagicGraph | Perimeter of Geometric Shapes
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      In this MagicGraph, you will learn about how to find the perimeter of a rectangular shape.
      Shown in the MagicGraph is a rectangular shape with its sides shown in grey.
      <br><br>
      Simply tap on a side to pick it. Once picked, the color of the side turns bright blue, and its length is added to the peripheral length.
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle and generate a new shape. You can tap on the
      <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Perimeter',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Perimeter of a Rectangle';
    this.$store.commit('navigation/changeTitle', title);
    /*
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    */
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Perimeter',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
          },
}
</script>
